/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.container {
	display: block;
	position: relative;
	padding-left: 35px;
	/* margin-bottom: 12px; */
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio button */
.container input[type='radio'] {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #fff;
	border-radius: 50%;
	border: 2px solid #999;
	display: flex;
	justify-content: center;
	align-items: center;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: #fafafa;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
	border: 2px solid #00b5f6;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #00b5f6;
}
